export const sentimentColors = {
  angry: '#c00000',
  disgusted: '#71ac47',
  fear: '#404040',
  happy: '#ffbf00',
  neutral: '#a6a6a6',
  sad: '#4472c4',
  surprised: '#ec7d32',
  curious: '#833b0b',
  engaged: '#702fa0',
  notEngaged: '#bea689',
  laughter: '#FFB800',
  angst: '#00A593',
  bittersweet: '#833B7F',
  contempt: '#EA462F',
  despair: '#5F5F5F',
  guilt: '#4E9967',
  victory: '#E94C63',
  remorse: '#8D9000',
  shock: '#F29730',
  delight: '#FFB800',
  disapproval: '#A26EFF',
  outrage: '#EA462F',
  envy: '#CE74FF',
  enjoyment: '#FFDF8B',
  humor: '#D7A1F9',
}

export const groupSentimentColors = [
  '#EA462F',
  '#71ac47',
  '#6f1996',
  '#8c0250',
  '#32e195',
  '#e13224',
  '#167b2b',
  '#cf84cb',
  '#e9d737',
  '#85b973',
  '#df43b0',
  '#323d96',
  '#faa566',
  '#68affc',
  '#4220f0',
  '#916030',
  '#52c9d3',
  '#214d4e',
  '#e8ced5',
  '#5979fe',
]

export const groupMaleFemaleColors = {
  male: '#017EFF',
  female: '#FF0099',
}

export const meanLineSeries = {
  type: 'line',
  name: 'Average',
  color: '#9d9d9d',
  strokeWidth: 5,
  dashArrayValue: 0,
  zIndex: 2,
}

export const benchmarkSeries = {
  type: 'line',
  name: 'Benchmark',
  color: '#32cd32',
  strokeWidth: 5,
  dashArrayValue: 5,
  zIndex: 2,
}

export const creativeSentiments = ['happy', 'angry', 'fear', 'disgusted', 'surprised', 'sad']
export const legalSentiments = ['bittersweet', 'disapproval', 'outrage', 'victory', 'shock']

export const emotionDisplayNames = {
  angst: 'Angst',
  curious: 'Curiosity',
  disgusted: 'Aversion',
  bittersweet: 'Bittersweet',
  angry: 'Critical',
  delight: 'Delight',
  disapproval: 'Disapproval',
  happy: 'Joy',
  outrage: 'Outrage',
  sad: 'Sadness',
  shock: 'Shock',
  fear: 'Skeptical',
  surprised: 'Surprised',
  victory: 'Victory',
  humor: 'Humor',
  enjoyment: 'Enjoyment',
  rapport: 'Rapport',
}

const options = () => ({
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    type: 'numeric',
    tickPlacement: 'between',
    floating: true,
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  chart: {
    id: 'sentimentChart',
    toolbar: {
      show: false,
    },
    redrawOnParentResize: true,
    redrawOnWindowResize: true,
    zoom: {
      enabled: false,
    },
    fontFamily: 'Roboto',
    animations: {
      enabled: true,
    },
  },
  yaxis: {
    min: 0,
    tickAmount: 4,
    decimalsInFloat: 0,
    labels: {
      offsetX: -5,
      rotate: -20,
      style: {
        colors: ['#848484'],
        fontSize: 8,
      },
    },
    axisTicks: {
      show: true,
      borderType: 'solid',
      color: '#848484',
      width: 6,
    },
  },
  grid: {
    show: true,
    borderColor: 'rgba(38, 50, 56, 0.05)',
    strokeDashArray: 0,
    position: 'back',
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
})

export default options
